import jekinraa from '../assets/logo/jekinraa.png';
import instagram from '../assets/icons/instagram.png';
import linkedin from '../assets/icons/linkedin.png';
import twitter from '../assets/icons/twitter.png';
import AppStore from '../assets/icons/AppStore.png';
import GooglePlay from '../assets/icons/GooglePlay.png'
import PrvacyPolicy from '../components/Footer/Support/PrivacyPolicy/HeroUI';
import { useNavigate } from 'react-router';

function FooterItems(props) {
   const navigate = useNavigate("")
   return (
      <div
         className=''
      >
         <div
            onClick={() => navigate(props.link)}
            target="_blank"
            className='text-[#E0E0E0] font-Kumbh400 max-w-[10rem] cursor-pointer'
         >

            {props.items}
         </div>
      </div>
   )
}

export default function Footer() {
   const iconstyle = ['w-[2rem] h-[2rem]']
   let socials = [
      <a
         href='https://www.instagram.com/jekinraa/'
         hrefLang=''
      >
         <img
            src={instagram}
            alt='Instagram icon'
            className={iconstyle}
         />
      </a>,

      <a
         href='https://www.linkedin.com/company/jekinraa/'
         hrefLang=''
      >
         <img
            src={linkedin}
            alt='Linkedin icon'
            className={iconstyle}
         />
      </a>,

      <a
         href='https://twitter.com/Jekinraa_'
         hrefLang=''
      >
         <img
            src={twitter}
            alt='Twitter icon'
            className={iconstyle}
         />
      </a>
   ]

   let company = [
      <a
         href='/'
         hrefLang=''
      >
         Home
      </a>,

      <a
         href='/about'
         hrefLang=''
      >
         About
      </a>,

      <a
         href='/FAQ/general'
         hrefLang=''
      >
         FAQ
      </a>
   ]

   let support = [{

      text: "Terms and Conditions",
      link: "/terms_conditions"
   },

   {

      text: "Chat with us",
      link: "https://whatsapp.com/channel/0029Va93vNpIyPtVtQBBuF02"
   },

   {
      text: "Privacy Policy",
      link: "/privacy_policy"
   },
   ]

   let gettheApp = [
      <a
         href="https://apps.apple.com/app/jekinraa-the-shopping-app/id6478771670"
         hrefLang=''
      >
         <img
            src={AppStore}
            alt='App Store icon'
         />
      </a >,
      <a
         href='https://play.google.com/store/apps/details?id=com.jekinraaapp'
         hrefLang=''
      >
         <img
            src={GooglePlay}
            alt='Google Play icon'
         />
      </a>
   ]

   return (
      <div
         className="bg-black overflow-hidden w-full px-3 sm:px-4 lg:px-20 xl:px-36 py-24 "
      >
         <div
            className="flex flex-col gap-14 sm:flex-col lg:flex-row lg:justify-between w-full  "
         >
            <div
               className="flex flex-col gap-5"
            >
               <div>
                  <img
                     src={jekinraa}
                     className='w-[8.6875rem] h-[2.4131875rem]'
                  />
               </div>
               <div>
                  <div
                     className='font-Kumbn400 max-w-[21.875rem] text-base text-[#D9DBE1]'
                  >
                     Craft Your Success Story with Jekinraa.
                  </div>
                  <div
                     className='font-Kumbn400 max-w-[21.875rem] text-base text-[#D9DBE1]'
                  >
                     Discover the World, One Local Gem at a Time.
                  </div>
               </div>
               <div
                  className='flex gap-3  '
               >
                  {
                     socials.map((val) =>
                        <FooterItems
                           items={val}
                        />)
                  }
               </div>
            </div>

            <div
               className="flex flex-col gap-5"
            >
               <div
                  className='text-bgcolor text-xl'
               >
                  Company
               </div>

               {
                  company.map((val) =>
                     <FooterItems
                        items={val}
                     />
                  )
               }
            </div>

            <div
               className="flex flex-col  gap-5"
            >
               <div
                  className='text-bgcolor text-xl'
               >
                  Support
               </div>

               {
                  support.map((val) => (
                     <FooterItems
                        items={val.text}
                        link={val.link}
                     />
                  ))
               }
            </div>

            <div
               className="flex flex-col gap-5"
            >
               <div
                  className='text-bgcolor text-lg'
               >
                  Download Here.
               </div>

               {
                  gettheApp.map((val) =>
                     <FooterItems
                        items={val}
                     />
                  )
               }
            </div>
         </div>
      </div>
   )
}