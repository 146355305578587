import { button } from '../../btnui';


function FeatureUIitems(props) {

   const style = {

      reverse: props.reverse ? "flex flex-col sm:flex-col md:flex-col lg:flex-row-reverse" : "flex flex-col sm:flex-col md:flex-col lg:flex-row "
   }


   return (
      <div className={"gap-8 pb-14 " + style.reverse}>
         <div
            className="flex flex-col flex-1 gap-2 py-10"
         >
            <div
               className="font-Kumbh500 font-medium  text-[1.38rem] leading-tight sm:text-[1.38rem] md:text-[2.5rem] max-w-[64.25rem]"
            >
               {props.headText}
            </div>
            <div
               className="text-[#828282] font-Kumbh400  max-w-[54.25rem] leading-[1.6rem] md:leading-[1.8rem] text-base sm:text-lg md:text-lg "
            >
               {props.labelText}
            </div>
         </div>
         <div
            className="bg-[#F1F1F1] flex  flex-1 rounded-lg"
         >
            <img
               src={props?.img}
               className="object-cover h-auto sm:h-auto w-full rounded-2xl"
            />
         </div>
      </div>
   )
}

export default function FeatureUI() {
   return (
      <div
         className="w-full lg:mt-36 xl:mt-44"
      >
         <div
            className="flex flex-col lg:gap-20"
         >
            {
               <FeatureUIitems
                  headText={"Elevating vendor success: empowering with Insights into business performance."}
                  img={require('../../../assets/images/Topsales.png')}
                  labelText={"Join our dynamic community of sellers and tap into a vast network of potential customers. Whether you're a budding entrepreneur or an established business, Jekinraa provides the perfect avenue to showcase your products and connect with shoppers in Nigeria."}
               />
            }
            {
               <FeatureUIitems
                  headText={"Create Payment Links: A seamless way to receive payments."}
                  img={require('../../../assets/images/accept.jpg')}
                  labelText={"We understand the importance of a seamless payment process. Our platform offers a unique feature that enables vendors to create payment links for their products. This feature is perfect for vendors who want to sell their products on social media platforms or their website. With Jekinraa, you can create payment links for your products and share them with your customers in a few simple steps. "}
                  reverse
               />
            }
            {
               <FeatureUIitems
                  headText={"Social Media Integration: Extend Your Reach to Your App."}
                  img={require('../../../assets/images/instagramHint.png')}
                  labelText={"We're all about simplifying your journey. Our platform offers a unique feature that enables vendors to effortlessly link products they've showcased on social media platforms like Instagram directly to their store listing. No need to duplicate efforts or manage multiple listings. Showcase your products where your audience already engages, and watch them seamlessly transition to your app for hassle-free purchasing."}

               />
            }
         </div>
      </div>
   )
}

