import { useState, useEffect, useContext, createContext } from "react";
import General from "./subPages/General";
import Vendor from "./subPages/Vendor";
import Customers from "./subPages/Customers";



export const UseContext = createContext();

function SubFAQUItems(props) {
   return (
      <div
         className="bg-[#FBFBFB] border-[0.05rem] border-[#E0E0E0] rounded-lg py-6 px-7 basis-[49%] max-w-[36rem] "
      >
         <div
            className="text-primaryDark font-Kumbh500 font-medium text-base"
         >
            {props.Question}
         </div>
         <div
            className="text-[#333] font-Kumbh400 font-normal text-base"
         >
            {props.Answer}
         </div>
      </div>
   )
}

export default function SubFAQUI() {
   const FAQbox = [
      {
         Question: "What is Jekinraa about?",
         Answer: "Jekinraa is an online platform that connects local artisans and entrepreneurs with discerning shoppers. We showcase unique, locally-sourced, and artisanal products, creating a vibrant community of creators and consumers."
      },
      {
         Question: "How can I become a Seller on Jekinraa?",
         Answer: "To become a Seller on Jekinraa, you can sign up as a vendor through our registration process on our app. We'll guide you through the necessary steps to set up your store and start listing your products."
      },
      {
         Question: "What fees are associated with selling on Jekinraa?",
         Answer: "Jekinraa charges a 2% transaction fee on the total purchase amount for each sale. This fee helps support the platform and maintain our services."
      },
      {
         Question: "How do I manage my products and listings on Jekinraa?",
         Answer: "As a Seller, you'll have access to a Seller App where you can manage your products, inventory, and orders. It's your control center for all your business activities on Jekinraa."
      },
      {
         Question: "How do I make a purchase on Jekinraa?",
         Answer: "Making a purchase on Jekinraa is easy. Simply browse our platform, find products you love, add them to your cart, and complete your purchase at the checkout."
      },
      {
         Question: "Is my payment information secure on Jekinraa?",
         Answer: "Yes, we prioritize your security. Jekinraa uses secure payment gateways and encryption to protect your payment information. We also never share your personal information with third parties."
      },
      {
         Question: "What is the return policy on Jekinraa?",
         Answer: "Each Seller on Jekinraa may have their own return policy, so it's important to review their terms. However, we do have a standardized return process to ensure a smooth experience for both Shoppers and Sellers."
      },
      {
         Question: "How can I engage with other members of the Jekinraa community?",
         Answer: "Follow us on social media to stay up to date on the latest news and events. You can also join our mailing list to receive updates on new products, promotions, and more."
      }
   ]


   const items = {
      Box: <div
         className="w-full mb-48 mt-6"
      >
         <div
            className="flex  flex-col gap-3 items-baseline md:flex-row flex-wrap "
         >
            {
               FAQbox.map((item) => (
                  <SubFAQUItems
                     Question={item.Question}
                     Answer={item.Answer}
                  />
               ))
            }
         </div>
      </div>

   }

   const [FaqItems, setFAQItems] = useState(items.Box)

   return (
      <div>
         {FaqItems}
      </div>

   )
}
