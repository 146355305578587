export default function FeatureUI() {
   return (
      <div
         className="w-full mt-20 md:mt-40 pb-16 mb-8 md:pb-28 md:mb-12 xl:mt-44"
      >
         <div
            className="flex flex-col md:flex-row md:justify-between gap-3 "
         >
            <div
               className="font-Kumbh500 max-w-[24.75rem] font-semibold text-primaryDark text-[2.5rem] sm:text-5xl md:text-[3.5rem]  tracking-tight"
            >
               Our Story
            </div>
            <div
               className='flex flex-col gap-2'
            >
               <div
                  className="font-Kumbh500 text-[#222] text-justify font-medium max-w-[36.75rem] text-base sm:text-base md:text-lg  md:leading-[1.7rem]"
               >
                  At Jekinraa, we believe that the world is full of exceptional creators, artisans, and entrepreneurs whose unique products deserve to be celebrated. We've set out on a mission to bridge the gap between these talented individuals and those seeking the extraordinary.
               </div>
               <div
                  className="font-Kumbh400 text-[#333] font-normal text-justify max-w-[36.75rem] text-base sm:text-base md:text-lg md:leading-[1.7rem] mt-8"
               >
                  Jekinraa is more than just an e-commerce platform; it's a thriving community that celebrates individuality and craftsmanship. Our platform empowers local artisans and small-scale entrepreneurs to showcase their one-of-a-kind creations to a global audience. We provide the stage where dreams come to life.
               </div>
               <div
                  className="font-Kumbh400 text-[#333] font-normal text-justify max-w-[36.75rem] text-base sm:text-base md:text-lg md:leading-[1.7rem] mt-8"
               >
                  For those seeking products with a story, character, and authenticity, Jekinraa is your treasure trove. Our curated shopping experience introduces you to a world of locally-sourced and artisanal gems. You'll find items that tell a unique tale and become a part of your own story.
               </div>
               <div
                  className="font-Kumbh400 text-[#333] font-normal text-justify max-w-[36.75rem] text-base sm:text-base md:text-lg md:leading-[1.7rem] mt-8"
               >
                  What sets Jekinraa apart is our commitment to building a community. We're not just a marketplace; we're a network of dreamers, creators, and those who appreciate their work. With personalized recommendations, direct vendor interactions, and a deep sense of trust, our platform fosters connections that go beyond transactions.
                  <br /><br />
                  Join us on this journey to turn dreams into reality, one purchase at a time. Whether you're a visionary Seller ready to craft your success story or a Shopper searching for the extraordinary, you're welcome at Jekinraa.
               </div>
            </div>
         </div>
      </div>
   )
}