import { memo, useEffect, useRef, useState } from 'react';
import hero from '../../../assets/images/hero.png';
import { useScroll, motion, useMotionValueEvent } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { button } from '../../btnui';




function HeroUI({ products }) {

   return (
      <motion.div
         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         exit={{ opacity: 0 }}
         className="w-full mb-10 bg-bgcolor break-words bg-[url('./assets/images/ellipse.png'),_url('./assets/images/spots.png')] bg-no-repeat bg-left-top pt-5 md:pt-16 px-3 sm:px-4 lg:px-24"
      >
         <div
            className="flex flex-col gap-7  break-words items-center"
         >
            <h1
               className=" font-Kumbh500 font-medium text-primaryDark break-words max:w-[75rem] text-[2.5rem] sm:text-5xl md:text-7xl tracking-tight md:tracking-[0.019rem] leading-tight md:leading-[5.15rem] text-center"
            >
               Shop Smart. Sell Easy. Live Better.
               <span
                  className="block"
               > with <span
                  className="text-primaryLight"
               >
                     Jekinraa.
                  </span>
               </span>
            </h1>
            <h2
               title="Seller or Shopper"
               className="font-Kumbh400 text-center break-words  text-[#535353]  max-w-[57.9375rem] text-lg sm:text-lg md:text-xl  md:leading-[2rem]"
            >
               Get Started Now.
               <br></br>Available on IOS and Android.
            </h2>
            <div
               className="flex justify-center items-center space-x-2"
            >
               {button.androidBtn}
               {button.iosBtn}
            </div>
            <motion.div className='w-full flex-1 '>
               <div
                  className="w-auto relative h-32 -ml-32 my-24 md:32"
               >
                  {
                     products?.map((item, index) => (
                        <motion.img
                           initial={{ opacity: 1 }}
                           whileHover={{
                              scale: 1.5,
                              transition: { duration: 1 },
                              opacity: 1,
                              zIndex: 2,
                           }}
                           transition={{
                              ease: "easeInOut",
                              duration: 0.1,
                           }}
                           whileTap={{ scale: 0.9 }}
                           src={item?.images[0].url}
                           style={{
                              left: 70 * (index),

                           }}
                           className={"object-cover h-32 w-32 rounded-full shadow-lg absolute"}
                        />
                     ))
                  }
               </div>
            </motion.div>
         </div>
      </motion.div>
   )
}

export default memo(HeroUI)