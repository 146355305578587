import { button } from '../../btnui';
import spaceRocket from '../../../assets/icons/spaceRocket.png';

export default function CTAUI() {
   return (
      <div
         className="bg-primaryLight  rounded-lg flex h-[26rem] lg:h-[32rem] mt-12 md:mt-20  w-full relative overflow-hidden shadow-[16px_24px_50px_0px_rgba(35,0,39,0.24)]">

         <div className="border border-[#F6A8FF] w-full h-[26rem] lg:h-[32rem] absolute top-6 left-6 md:top-8 md:left-8 lg:top-12 lg:left-12 rounded-lg"></div>
         <div className="border border-[#F6A8FF] w-full h-[26rem]  lg:h-[32rem] absolute bottom-6 right-6 md:bottom-8 md:right-8 lg:bottom-12 lg:right-12 rounded-lg"></div>
         <div
            className="flex flex-col justify-center items-center w-full gap-5 z-10"
         >
            <div
               className='w-full flex justify-center items-center'
            >
               <img
                  src={spaceRocket}
                  className='w-[3.875rem] h-auto' />
            </div>
            <div
               className="text-2xl font-bold md:text-[2rem] lg:text-[2.5rem] px-8 md:px-6 md:leading-[2.8rem] lg:px-9 xl:px-3 leading-[2.2rem] lg:leading-[3.4rem] max-w-[51.1875rem] font-Kumbh500 text-center text-bgcolor"
            >
               App has been launched!!!
            </div>
            <div className='flex flex-row space-x-2'>
               <div className='bg-white rounded-lg flex flex-row space-x-2 p-[1px]'>
                  {button.androidBtn}
               </div>
               <div className='bg-white rounded-lg flex flex-row space-x-2 p-[1px]'>
                  {button.iosBtn}
               </div>
            </div>
         </div>
      </div>
   )
}