import enroute from "../../../assets/images/enroute.png";
import phoneL from "../../../assets/images/phoneL.png";
import phoneS from "../../../assets/images/phoneS.png";
import phonepayout from "../../../assets/images/iphonepayout.jpeg";
import phonestats from "../../../assets/images/iphonestats.jpeg";
import phoneCard from "../../../assets/images/cardPage.png";
import world from "../../../assets/images/world.png";

function LeadUIitems(props) {
   return (
      <div className={props?.type == "mini" ? "md:w-2/4" : "md:w-3/4"}>
         <div
            className="flex flex-col relative  z-1 border-[#E0E0E0] border bg-[#FBFBFB] md:gap-3 shrink rounded-lg px-4  md:px-6 pt-4 md:pt-7"
         >
            <div
               className="font-Kumbh500 font-medium  pb-1 text-[#222] text-lg  md:text-[2rem]"
            >
               {props.headText}

            </div>
            <div
               className="text-[#7E7E7E]  font-Kumbh400 max-w-[24.0625rem] leading-tight md:leading-normal text-sm sm:text-sm md:text-lg "
            >
               {props.labelText}
            </div>
            <div
               className="flex flex-1 items-end"
            >
               <div className="flex flex-1  justify-center">
                  {props.image}
               </div>
            </div>
         </div>
      </div >
   )
}

export default function LeadUI() {
   let level1 = [
      {
         headText: "Connect with Vendors",
         labelText: "Jekinraa offers an extensive selection to cater to every shopper's needs and preferences.",
         type: "major",
         image: <img
            src={phoneL}
            alt="Map"
            className="  md:px-2 w-auto h-[14.6rem]"

         />
      },
      {
         headText: "Real-time Order Tracking",
         labelText: "Keep an eye on your orders every step of the way.",
         type: "mini",
         image: <img
            src={enroute}
            alt="Map"
            className="md:px-2 2xl:w-[50rem]"

         />
      },
   ]

   let level2 = [
      {
         headText: "Expand Your Reach",
         labelText: "When you sell on Jekinraa, your products transcend borders. Your reach expands, and so do your opportunities.",
         type: "mini",
         image: <img
            src={world}
            alt="Map"
            className="md:px-2 w-auto h-[18.3rem]"

         />
      },
      {
         headText: "Flexible Delivery and Pickup",
         labelText: "Enjoy the freedom to choose delivery or pickup based on what suits you best.",
         type: "major",
         image: <img
            src={phoneS}
            alt="Checkout page"

            className="h-[11.9rem] md:h-[20rem] pl-4 lg:pl-7"
         />
      },
   ]

   let level3 = [

      {
         headText: "Showcase Your Craftsmanship",
         labelText: "With your Seller account, you gain access to a platform that champions local artisans, offering your creations to a diverse market.",
         type: "major",
         image: <img
            src={phonepayout}
            alt="Map"
            className="md:px-2 w-auto h-[12.1rem]"
         />
      },
      {
         headText: "Easy Payment Processing",
         labelText: "Receive payments from your customers with ease.",
         type: "mini",
         image: < img
            src={phoneCard}
            alt="Checkout page"

            className=" pl-4 lg:pl-7"
         />
      }
   ]

   return (
      <div
         className="w-full  bg-bgcolor mt-10 "
      >
         <div
            className="flex flex-col gap-10 justify-center items-center"
         >
            <div className="flex flex-col justify-center items-center ">
               <div
                  className="font-Kumbh400 font-medium  text-center text-3xl md:leading-14 leading-2 sm:text-5xl md:text-5xl max-w-[64.25rem] mb-12 md:mb-20"
               >
                  Why Choose Jekinraa..
               </div>
               <div
                  className="flex w-full justify center flex-col sm:flex-col md:flex-col lg:flex-col xl:flex-row-reverse  gap-10 sm:gap-10 md:gap-10 lg:gap-10 xl:gap-4"
               >
                  {
                     level1.map((val) => (
                        <LeadUIitems
                           headText={val.headText}
                           labelText={val.labelText}
                           image={val.image}
                           type={val.type}
                        />
                     ))
                  }
               </div>
               <div
                  className="my-4 flex w-full justify-center flex-col sm:flex-col md:flex-col lg:flex-col xl:flex-row-reverse  gap-10 sm:gap-10 md:gap-10 lg:gap-10 xl:gap-4"
               >
                  {
                     level2.map((val) => (
                        <LeadUIitems
                           headText={val.headText}
                           labelText={val.labelText}
                           image={val.image}
                           type={val.type}
                        />
                     ))
                  }
               </div>
               <div
                  className="my-4 flex w-full justify center flex-col sm:flex-col md:flex-col lg:flex-col xl:flex-row-reverse  gap-10 sm:gap-10 md:gap-10 lg:gap-10 xl:gap-4"
               >
                  {
                     level3.map((val) => (
                        <LeadUIitems
                           headText={val.headText}
                           labelText={val.labelText}
                           image={val.image}
                           type={val.type}
                        />
                     ))
                  }
               </div>
            </div>

         </div>
      </div>
   )
}