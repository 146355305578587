import FeatureUI from "../components/NavBar/AboutUs/FeatureUI";
import HeroUI from "../components/NavBar/AboutUs/HeroUI";
import TeamUI from "../components/NavBar/AboutUs/TeamUI";
import NavBar from "../layouts/NavBar";
import Footer from "../layouts/Footer";




export default function AboutUs() {
   return (
      <div 
         className="overflow-x-hidden h-full w-full"
      >
         <header>
            <NavBar />
         </header>

         <main>
            <HeroUI />
            <section
               className="px-3 sm:px-4 lg:px-20 xl:px-36"
            >
               <FeatureUI />
               <TeamUI />
            </section>
         </main>
         
         <footer>
            <Footer/>
         </footer>
      </div>
   )
} 